import React from 'react'
import styles from './style.module.scss'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export default () => {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "4040.png" }) {
                childImageSharp {
                    fluid(maxWidth: 618, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <div className="op_block">
            <div className={styles.content}>
                <Img fluid={data.file.childImageSharp.fluid} className={styles.content_img} />
                <div className={styles.content_title}>
                    Page Not Found
                </div>
            </div>
        </div>
    )
}